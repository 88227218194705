<template>
  <div>
    <CCard class="c-card">
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>
      <CCardBody>
        <div class="card-header-title">
          <CCardTitle>Alerts & Notifications</CCardTitle>
          <CButton class="c-btn" color="light" @click="onUpdateNotifications">Update Change</CButton>
        </div>

        <CForm class="notification-form">
          <CInputCheckbox label="(Software Name) Communication"/>
          <p>Get news, announcements , and product updates</p>
          <CInputCheckbox label="Plan are about to expire"/>
        </CForm>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
  export default {
      name: 'Notification',
      data() {
        return {
          infoList: [],
        }
      },
      methods: {
        toast(header, message, color) {
          var self = this;
          self.infoList.push({
            header: header,
            message: message,
            color: color,
          });
        },
        onUpdateNotifications() {
          this.toast("Info", "Successfully updated", "success");
        }
      }
      
  }
</script>

<style>
.c-card {
  max-width: 59rem;
  width: 100%;
  margin: 0 auto;
}

.card-header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notification-form {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.c-btn {
  padding: 8px 16px;
  font-size: 16px;
  line-height: 22px;
  border-radius: 50px;
  width: 176px;
}
</style>
